<template>
    <tabs class="fixed bottom-0 w-full z-1000 bg-white" grow persist-border reverse-border v-model="activeTabName">
        <tab v-for="(navItem, navItemKey) in items" :key="navItemKey" :value="navItem.value" :model-value="navItem.active" :to="navItem.to" @click="event => handleNavItemClick(event, navItem)" :exact="navItem.exact" :aria-label="navItem.alt" :height="48">
            <div class="relative flex flex-column items-center justify-around">
                <avatar v-if="navItem.image" :src="navItem.image" :size="26" round class="border-2" :class="navItem.active ? 'border-primary' : 'border-gray-450'" :alt="navItem.alt"/>
                <icon v-else-if="navItem.icon" :icon="navItem.icon" :color="navItem.active ? 'primary' : 'gray-450'" :size="26" force-size />
                <badge v-if="navItem.badge === true" absolute right top rounded-full small color="secondary"/>
                <badge v-else-if="navItem.badge" absolute right top rounded-full color="secondary">{{ navItem.badge }}</badge>
            </div>
        </tab>
    </tabs>
</template>

<script setup lang="ts">
import {mdiBellOutline, mdiMagnify, mdiViewDashboardOutline} from "@mdi/js";
import {DefaultAvatar, scrollToPosition} from "@spoferan/spoferan-ts-core";
import {useIndexStore} from "../../store";
import {useOnline} from "@vueuse/core";
import {useGlobalStore} from "@spoferan/nuxt-spoferan/store";
const componentExistenceBodyClass = 'has-mobile-bottom-bar';

const route = useRoute();
const {$web, $app, $auth} = useNuxtApp();
const {t} = useI18n();
const indexStore = useIndexStore();
const globalStore = useGlobalStore();
const isOnline = useOnline();

const activeTabName = ref('dashboard');

const items = computed(() => {
	const notificationsCount = Math.max(0, globalStore.unreadNotificationsCount ?? 0);
	const displayNotificationsCount = notificationsCount && notificationsCount > 9 ? '9+' : notificationsCount;
	const isUnconfirmedUser = $auth.user && !$auth.user.is_confirmed

	return [
		{to: $web('dashboard'), exact: true, active: activeTabName.value === 'dashboard', value: 'dashboard', icon: mdiViewDashboardOutline, alt: t('label.dashboard')},
		{to: $web('search'), active: activeTabName.value === 'search', value: 'search', icon: mdiMagnify, alt: t('label.search')},
		{to: $web('notifications'), active: activeTabName.value === 'notifications', value: 'notifications', badge: displayNotificationsCount && displayNotificationsCount !== 0 ? displayNotificationsCount : (!indexStore.hasConfiguredPushNotifications || isUnconfirmedUser || !isOnline.value), icon: mdiBellOutline, alt: t('label.notifications')},
		{to: $web('my.index'), image: $auth.user ? $auth.user.avatar_thumbnail : DefaultAvatar, badge: $auth.isImpersonating, active: activeTabName.value === 'my', value: 'my', alt: t('label.my_profile')}
	];
});

const handleNavItemClick = () => {
	scrollToPosition(0);
}

function initActiveTabName() {
	if (route.path.startsWith($web('search'))) {
		activeTabName.value = 'search';
		return;
	}
	if (route.path.startsWith($web('notifications'))) {
		activeTabName.value = 'notifications';
		return;
	}
	if (route.path.startsWith('/my')) {
		activeTabName.value = 'my';
		return;
	}
	activeTabName.value = 'dashboard';
	return;
}

initActiveTabName();

onMounted(() => {
	// Adding a class to the body tag allows us to adapt the style of other components, e.g., the PageContent
	// Required in next tick as other app bottom bar might remove the class on destroy
	document.body.classList.add(componentExistenceBodyClass);
});

onBeforeUnmount(() => {
	document.body.classList.remove(componentExistenceBodyClass);
});

</script>